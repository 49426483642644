// extracted by mini-css-extract-plugin
export var bigBlueBox = "big-blue-box-module--bigBlueBox--0437b";
export var body = "big-blue-box-module--body--18356";
export var bodyLarge = "big-blue-box-module--bodyLarge--123bd";
export var bodyLargeLight = "big-blue-box-module--bodyLargeLight--9bc99";
export var bodySmall = "big-blue-box-module--bodySmall--2149d";
export var bold = "big-blue-box-module--bold--3b9d5";
export var containerAdj = "big-blue-box-module--containerAdj--a3f5e";
export var eyebrow = "big-blue-box-module--eyebrow--7ec99";
export var filter = "big-blue-box-module--filter--72df5";
export var homepageHeadline = "big-blue-box-module--homepageHeadline--89b1f";
export var internalHeadline = "big-blue-box-module--internalHeadline--65167";
export var italic = "big-blue-box-module--italic--76524";
export var link = "big-blue-box-module--link--50e9d";
export var linkSmall = "big-blue-box-module--linkSmall--481d5";
export var quote = "big-blue-box-module--quote--7bf92";