import { hasNames } from "../../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useRef } from "react";



import BigBlueBox from "../../components/big-blue-box";
import ContactUsFooter from "../../components/contact-us-footer";
import FiftyFifty from "../../components/fifty-fifty";
import Frame from "../../components/frame";
import { HeadMeta } from "../../components/head-meta";
import InteractiveScroll from "../../components/interactive-scroll";
import InternalHero from "../../components/internal-hero";



import CreditLeaf from "../../images/private-credit.leaf.svg";
import EquityLeaf from "../../images/private-equity.leaf.svg";



import * as com from "../../styles/common.module.scss";
import * as grd from "../../styles/grid.module.scss";
import * as styles from "../../styles/partnerships--investors.module.scss";
import * as typ from "../../styles/typography.module.scss";


export const Head = () => {
	return <HeadMeta title="Our Partnership with Investors" />;
};
const InvestorsPage = () => {
	const heroRef = useRef(null);
	const valueCreationRef = useRef(null);
	const flexibilityRef = useRef(null);
	const interstitialRef = useRef(null);
	const solutionsRef = useRef(null);
	const sourcingRef = useRef(null);
	const contactRef = useRef(null);

	const scrollableLocations = [
		{
			label: "Investors",
			ref: heroRef,
		},
		{ label: "Value Creation", ref: valueCreationRef },
		{ label: "Flexibility", ref: flexibilityRef },
		{ label: "Fully Invested", ref: interstitialRef },
		{ label: "Solutions", ref: solutionsRef },
		{ label: "Sourcing", ref: sourcingRef },
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollableLocations} />
			<InternalHero
				ref={heroRef}
				eyebrow={"Investors"}
				backdrop={
					<StaticImage
						src={`../../images/heros/valley.jpg`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>Alignment leads to better investor outcomes.</h1>
				<p>
					With founders and Cynosure meaningfully invested, we are aligned with
					our investors. Alignment ensures we are all focused on achieving the
					long-term goals of the organization for the benefit of our partners{" "}
					<u>and</u> investors.
				</p>
			</InternalHero>

			<FiftyFifty reverseMobile={true} useRef={valueCreationRef}>
				<StaticImage
					src="../../images/string-investor.jpg"
					alt="Value Creation"
					aspectRatio={1 / 1}
					layout="constrained"
					width={700}
					height={700}
				/>
				<div className="extra-gap">
					<div className={typ.eyebrow}>Value Creation</div>
					<h2 className={com.browedHeader}>
						An active and uniquely tailored approach to value creation.
					</h2>
					<div className={typ.bodyLargeLight}>
						Typically providing the first institutional capital, our
						collaborative approach creates value for our investors by creating
						value with the founder and management-owners we partner with.
						Whether we're assisting with operations, organizational development,
						capital structure, or M&A, we have the resources, alignment and
						risk-management focus to drive optimal outcomes.
					</div>
				</div>
			</FiftyFifty>

			<FiftyFifty useRef={flexibilityRef}>
				<div className="extra-gap">
					<div className={typ.eyebrow}>Flexibility</div>
					<h2 className={com.browedHeader}>
						Optimizing solutions for partner companies—that’s the Cynosure
						difference.
					</h2>
					<div className={typ.bodyLargeLight}>
						Differentiated approach provides our investors with unique
						investment opportunities. The success of our strategy hinges on
						underwriting creative solutions, including the ability to structure
						minority investments.
					</div>
				</div>
				<StaticImage
					src="../../images/investors-flexibility.jpg"
					alt="Flexibility"
					aspectRatio={1 / 1}
					layout="constrained"
					width={700}
					height={700}
				/>
			</FiftyFifty>

			<BigBlueBox ref={interstitialRef}>
				<h2>
					We’re fully invested in the success of
					<br className={styles.specialBreak} /> our partner companies.
				</h2>
			</BigBlueBox>

			<div
				className={hasNames(styles.pageSpecial, grd.container)}
				ref={solutionsRef}>
				<div className={hasNames(styles.head)}>
					<div className={hasNames(typ.eyebrow, styles.brow)}>Solutions</div>
					<h2 className={styles.header}>
						Our investment solutions focus on private growth equity and private
						credit.
					</h2>
				</div>
				<div className={hasNames(styles.content)}>
					<div className={hasNames(styles.message)}>
						<div className={hasNames(styles.imageSide)}>
							<EquityLeaf />
						</div>
						<div className={hasNames(styles.contentSide)}>
							<h4>Private Growth Equity</h4>
							<p>
								Cynosure targets private growth equity investments with the
								flexibility to structure each investment, including ownership
								levels, in a variety of ways. We typically invest between $25M
								and $75M+ on each opportunity and focus primarily on North
								American-based companies in Financial Services, Consumer
								Services, and Business / Industrial Services.
							</p>
						</div>
					</div>
					<div className={hasNames(styles.message)}>
						<div className={hasNames(styles.imageSide)}>
							<CreditLeaf />
						</div>
						<div className={hasNames(styles.contentSide)}>
							<h4>Private Credit</h4>
							<p>
								Targeting private credit opportunities and building on the
								foundational alignment of our private growth equity strategy,
								our credit strategy focuses on similar situations while
								investing $5M to $50M, usually senior in the capital structure.
							</p>
						</div>
					</div>
				</div>
			</div>

			<FiftyFifty reverseMobile={true} useRef={sourcingRef}>
				<StaticImage
					src="../../images/founders-sourcing.jpg"
					alt="Sourcing"
					aspectRatio={1 / 1}
					layout="constrained"
					width={700}
					height={700}
				/>
				<div className="extra-gap">
					<div className={typ.eyebrow}>Sourcing</div>
					<h2 className={com.browedHeader}>
						Partnering with founders and management-owners requires a
						specialized approach to sourcing.
					</h2>
					<div className={typ.bodyLargeLight}>
						Our primary source of opportunity comes from our network of current
						and former management teams, board members, and industry contacts,
						along with trusted advisors with whom our Founder and
						Management-Owner partners have professional relationships.
					</div>
				</div>
			</FiftyFifty>

			<ContactUsFooter
				text="Want to align yourself with Cynosure and our Founder and Management-Owner partners?"
				ref={contactRef}
			/>
		</Frame>
	);
};

export default InvestorsPage;
