import { hasNames } from "../utils";
import * as React from "react";
import { forwardRef } from "react";

import * as styles from "../styles/big-blue-box.module.scss";
import * as grd from "../styles/grid.module.scss";

const BigBlueBox = forwardRef((props, ref) => {
	const { children } = props;

	return (
		<div ref={ref} className={hasNames(styles.bigBlueBox)}>
			<div className={hasNames(grd.container, styles.containerAdj)}>
				{children}
			</div>
		</div>
	);
});

export default BigBlueBox;
